export function autoSubscribe() {
  return function (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: unknown[]) {
      const result = originalMethod.apply(this, args);
      if (result && result.subscribe) {
        result.subscribe(() => {
          document.dispatchEvent(new Event('dialog-close'));
        });
      }
      return result;
    };

    return descriptor;
  };
}
