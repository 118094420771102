<div class="flex flex-col">
  <core-label type="module-title" [label]="header()" />
  <p class="mt-4">{{ message() }}</p>

  <div class="flex justify-items-end mt-8">
    <core-button
      (click)="onCancel()"
      class="ml-auto mr-2"
      type="outline"
      [label]="cancelText()"
    ></core-button>
    <core-button (click)="onConfirm()" [label]="confirmText()"></core-button>
  </div>
</div>
