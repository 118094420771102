<div
  [ngClass]="{
    'min-w-[300px] max-w-[400px]': size === 'sm',
    'min-w-[500px]': size === 'md',
    'min-w-[800px] max-w-[1200px]': size === 'lg',
    'min-w-[1200] max-w-[1400px]': size === 'xl',
  }"
  class="p-2"
>
  <div class="flex justify-between items-center">
    <p class="text-lg font-bold">{{ header }}</p>
    <button
      (click)="closeDialog()"
      class="w-[28px] h-[28px] hover:cursor-pointer"
    >
      <img src="/icons/close-square.svg" alt="close" />
    </button>
  </div>
  <div class="">
    <ng-container #dialogContainer></ng-container>
  </div>
</div>
